<template>
    <div class="topic">
        <div class="topic-title">专题服务 <div class="topic-more" @click="gotoTopic">查看更多></div></div>
        <div class="topic-list">
            <div v-for="(item, index) in topicInfo" :key="item.id"  :class="'topic-box topic-' + (index + 1)"  
            :style="{'background-image': 'url('+ $getImage(item.imgCode)+')'}"
            @click="gotoTopicDetail(item.linkUrl)">
                <div class="topic-name">
                    {{item.adTitle}}
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {ref, onMounted, getCurrentInstance} from 'vue'
export default {
    setup(){
        const {proxy} = getCurrentInstance();

        const topicInfo = ref([]);

        function getTopics(){
            const url = '/core/cms/a/webAdInfo/list?noPage=1&typeCode=WEB_HOME_SPECIAL';
            proxy.$http.post(url).then(res=>{
                if(res.data.state == 0){
                    // console.log(res.data.result);
                    topicInfo.value = res.data.result;
                }
            }).catch(err=>{

            })
        }

        onMounted(()=>{
            getTopics();
        });


        return {topicInfo}
    },
    methods:{
        gotoTopic(){
            this.$router.push({name:'Topic', path: '/topic'})
        },
        gotoTopicDetail(id){    //此ID填写在 linkUrl
            this.$router.push({name:'TopicDetail', path: '/topicDetail', query: {id:id}})
        }
    }
}
</script>

<style lang="less" scoped>
@import url(../../../public/static/css/index.less);
    .topic{
        display: inline-block;
        width: 585px;
        margin-right: 13px;
        font-size: 0;
        .topic-title{
            background: #007EEC;
            font-family: SourceHanSansCN-Medium;
            font-size: 16px;
            color: #FFFFFF;
            letter-spacing: 0;
            text-align: justify;
            line-height: 36px;
            padding-left: 23px;
            padding-right: 13px;
            .topic-more{
                font-family: PingFangSC-Regular;
                font-size: 12px;
                color: #FFFFFF;
                float: right;
                cursor: pointer;
            }
            .topic-more:hover,.topic-more:focus{
                color: #c3c3c3;
            }
            
        }
        
        .topic-box{
            overflow: hidden;
            cursor: pointer;
            height: 144px;
            display: inline-block;
            box-sizing: border-box;
            font-family: SourceHanSansCN-Bold;
            font-size: 22px;
            color: #FFFFFF;
           
            
            text-align: center;
            display: flex;
            align-items: center;
            .topic-name{
                padding-left: 20px;
                padding-right: 20px;
                word-break: break-all;
                letter-spacing: 0.55px;
                text-shadow: 0 2px 3px rgba(0,0,0,0.50);
            }
        }
        .topic-more:focus,.topic-box:hover{
            color: #c3c3c3;
        }
        
    }
</style>

